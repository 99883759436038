import React, { useState } from 'react';
import axios from 'axios';

function UrlForm() {
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');
  const [emoteId, setEmoteId] = useState('');
  const [imageSrc, setImageSrc] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const regex = /^https:\/\/cdn\.7tv\.app\/emote\/([a-f0-9]{24})\/4x\.webp$/;
    const match = url.match(regex);
    if (match) {
      const id = match[1];
      setEmoteId(id);
      setError('');
      try {
        console.log(`Sending request to: https://api.captainpoposack.fr/emote/${id}`);
        const res = await axios.get(`https://api.captainpoposack.fr/emote/${id}`, {
          responseType: 'arraybuffer',
          withCredentials: true,
          headers: {
            'my-custom-header': 'abcd'
          }
        });

        console.log('Response received:', res);
        
        // Create a Blob from the response data
        const blob = new Blob([res.data], { type: 'image/gif' });
        const reader = new FileReader();
        
        reader.onloadend = () => {
          setImageSrc(reader.result);
          console.log('Image source set to:', reader.result);
        };
        
        reader.onerror = (err) => {
          console.error('FileReader error:', err);
          setError('Error reading image data');
        };

        reader.readAsDataURL(blob);
        
      } catch (err) {
        console.error('Error fetching image:', err);
        setError('Erreur lors de l\'envoi de la requête');
      }
    } else {
      setError('URL invalide. Assurez-vous qu\'elle est sous la forme https://cdn.7tv.app/emote/658fe7f0bf3a5b70a4b1e8ba/4x.webp');
      setEmoteId('');
      setImageSrc('');
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label>
          URL:
          <input
            type="text"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="https://cdn.7tv.app/emote/658fe7f0bf3a5b70a4b1e8ba/4x.webp"
            required
          />
        </label>
        <button type="submit">Submit</button>
      </form>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {emoteId && <p>ID de l'emote: {emoteId}</p>}
      {imageSrc && <img src={imageSrc} alt="Emote" />}
    </div>
  );
}

export default UrlForm;